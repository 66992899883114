import { Suspense, useContext, useState } from "react";
import { MenuBar } from "./MenuBar";
import { IMenuItem, MenuItem, getNavigation } from "../utils/menuitems";
import { useTranslation } from "react-i18next";
import { AppContext } from "../providers/AppProvider";

export default function Index(props: any) {
  const { t } = useTranslation();
  const app = useContext(AppContext).app;
  const locale = app.language;
  const nightMode = app.nightMode;
  const sideBars = app.sideBars;

  const getMenuItems = () => {
    const mainmenu = `welcome`;
    const automation = `automation`;
    const analysis = `analysis`;
    const architecture = `architecture`;
    const menuItems: IMenuItem[] = [
      new MenuItem("<<", getNavigation(mainmenu, locale, nightMode, sideBars), mainmenu),
      new MenuItem(t("menu." + automation), getNavigation(automation, locale, nightMode, sideBars), automation),
      new MenuItem(t("menu." + architecture), getNavigation(architecture, locale, nightMode, sideBars), architecture, undefined, false),
      new MenuItem(t("menu." + analysis), getNavigation(analysis, locale, nightMode, sideBars), analysis, undefined, false)
    ];
    return menuItems;
  };

  const [menu] = useState(getMenuItems());

  return (
    <MenuBar locale={locale} menuItems={menu} />
  )
}