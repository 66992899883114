import { Content } from "../buildingblocks/Content";
import { Suspense, useContext } from "react";
import { BrowserView, MobileView, TabletView, deviceDetect, deviceType, getUA } from 'react-device-detect';
import { isMobile, isMobileOnly, isTablet, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone } from 'react-device-detect';
import { mobileVendor, mobileModel, engineName, engineVersion } from 'react-device-detect';
import { fullBrowserVersion, browserVersion, browserName, isBrowser, isChrome, isChromium, isFirefox, isSafari, isMobileSafari, isOpera, isIE, isEdge, isYandex, isSamsungBrowser } from 'react-device-detect';
import { osName, osVersion, isIOS, isIOS13, isIPad13, isIPod13, isElectron } from 'react-device-detect';
import { AppContext } from "../providers/AppProvider";

import { useState, useEffect } from "react";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  const className = "ext-paragraph" + (indent ? "-indent" : "");

  return (
    <Suspense>
      <Content locale={locale} indent={indent}>
        <div className={className}>
          <SubContent/>
          {/* <SendMail/> */}
        </div>
      </Content>
    </Suspense>
  );
}

function SubContent() {
  //   import { isSmartTV, isWearable, isConsole, isAndroid, isWinPhone }  from 'react-device-detect';
  // import { osName, osVersion, isIOS, isIOS13, isIPad13, isIPod13, isElectron} from 'react-device-detect';

  return (
    <div>
      <div>
        OS: {osName}, {osVersion}
      </div>
      <BrowserView>
        Browser: {browserName}, {fullBrowserVersion}
      </BrowserView>
      <div>
        Engine: {engineName}, {engineVersion}
      </div>
      <MobileView>
        <div>
          Mobile: {mobileVendor}, {mobileModel}
        </div>
        <div>
          Mobile only: {isMobileOnly ? "true" : "false"}
        </div>
        <div>
          Tablet: {isTablet ? "true" : "false"}
        </div>
      </MobileView>
      <div>
          Wearable: {isWearable ? "true" : "false"}
      </div>
      <div>
          SMART TV: {isSmartTV ? "true" : "false"}
      </div>
      <div>
          Console: {isConsole ? "true" : "false"}
      </div>
      <div>
          Electron: {isElectron ? "true" : "false"}
      </div>
    </div>
  )
}

const SendMail = () => {
  const mail = {
    name: "Erix the Programmer",
    email: "eric@mijneureka.nl", 
    message: "Big message"
  }

  const requestOptions: any = {
    method: 'POST',
    headers: {},
    body: JSON.stringify(mail),
  };

  const [data] = useFetch("https://softex.nl/mail", requestOptions);

  const content = JSON.stringify(data);
  return (
    <div>
      {content}
    </div>
  );
};

const useFetch = (url: string, options: any) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};

export default Index;
