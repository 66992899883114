const resources = {
    "content": {
        "content/notfound": {
            "title": "Niet gevonden",
            "items": [
                {
                    "title": "",
                    "paragraph": "Bron bestaat niet."
                }
            ]
        },
        "content/exception": {
            "title": "Uitzonderingsfout",
            "items": [
                {
                    "title": "",
                    "paragraph": "Er heeft zich een uitzondering voorgedaan. Neem contact op met de beheerder van deze inhoud."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "Dit is een test."
                }
            ]
        },
        "welcome": {
            "title": "Welkom",
            "items": []
        },
        "vision": {
            "title": "Visie",
            "items": []
        },
        "contact": {
            "title": "Contact",
            "items": []
        },
        "contact/sent": {
            "title": "Contact",
            "items": []
        },
        "contact/notsent": {
            "title": "Contact",
            "items": []
        },
        "about": {
            "title": "Over",
            "items": []
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": []
        },
        "analysis": {
            "title": "Analyse",
            "items": []
        },
        "architecture": {
            "title": "Architectuur",
            "items": []
        }
    }
}

export default resources;