import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18nextOptions from './i18nextOptions'
import Axios from 'axios';
import resources from './resources'
import { i18nClientStepsConfig } from './environment'
import httpBackend from 'i18next-http-backend'
import chainedBackend from "i18next-chained-backend";
import localStorageBackend from "i18next-localstorage-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { resolve } from 'path';

const { defaultSignature, embedded, cache, externalServer, fallback } = i18nClientStepsConfig;

const ns = ["common", "content", "info", "forms"];

export const clearCache = (_ns?: string[]) => {
  (_ns == undefined ? ns : _ns).forEach((namespace: string) => {
    const prefix = cache.prefix;
    i18nextOptions.supportedLngs.forEach((language: string) => {
      const entry = prefix + language + "-" + namespace;
      const store = cache.session ? sessionStorage : localStorage;
      store.removeItem(entry);
    })
  });
}

if (embedded) {
  console.log(`Loading Client Embedded Resources`);

  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      ...i18nextOptions,
      resources
    })
} else {

  const detection = {
    order: ["querystring", "navigator", "htmlTag"],
    caches: []
  }

  console.log(`Loading Client Experimental Resources`);

  const backends: any[] = [];
  const configs: any[] = [];

  const backend = {
    backends,
    backendOptions: configs
  };

  if (cache?.enabled) {
    const { prefix, defaultVersion, versions, expirationTime, session } = cache;

    console.log(`Added Client Resources and Cache`);

    const config = {
      prefix,
      defaultVersion,
      versions,
      expirationTime,
      store: session ? window.sessionStorage : window.localStorage
    };

    backends.push(localStorageBackend);
    configs.push(config);
  }

  if (externalServer?.enabled) {
    const { url, path } = externalServer;
    const signature = externalServer.signature ? externalServer.signature : defaultSignature;
    const currentPath = `${url}${path}${signature}`;

    console.log(`Added Client Server Url ${currentPath}`);

    const config = {
      loadPath: `${currentPath}`,
      parse: (data: any) => {
        return data;
      },
      request: (options: any, url: any, payload: any, callback: any) => {
        Axios.get(url)
          .then((res) => {
            callback(null, res);
          })
          .catch((err) => {
            callback(err, null);
          });
      }
    }
    backends.push(httpBackend);
    configs.push(config);
  }

  if (fallback?.enabled) {
    const { path } = fallback;
    const signature = fallback.signature ? fallback.signature : defaultSignature;
    const currentPath = `${path}${signature}`;

    console.log(`Added Client Fallback ${currentPath}`);

    const fallbackBackend = resourcesToBackend((lng: string, ns: string, callback) => {

      let cachedResources = undefined;

      if (fallback.useCache) {
        const entry = `${cache.prefix}${lng}-${ns}`;
        const store = cache.session ? sessionStorage : localStorage;
        cachedResources = store.getItem(entry);

        // console.log(`cachedResources`);
        // console.log(cachedResources);
      }

      if (cachedResources) {
        callback(null, JSON.parse(cachedResources))
      } else {
        import(`./json-empty/${lng}/${ns}.json`)
          .then((resources) => {
            // console.log("Fallback");
            callback(null, resources)
          })
          .catch((error) => {
            // console.log("Fallback Error");
            // console.log(error);
            callback(error, null)
          })
      }

    });

    backends.push(fallbackBackend);
  }

  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(chainedBackend)
    .init({
      ...i18nextOptions,
      backend,
      detection,
      ns
    })
}    
