import './i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './styles/app.css';
import './styles/content.css';
import './styles/info.css';
import './styles/navigator.css';

import './json/en/info.json';
import './json/en/common.json';
import './json/en/content.json';
import './json/en/forms.json';
import './json/nl/info.json';
import './json/nl/common.json';
import './json/nl/content.json';
import './json/nl/forms.json';
import './json/de/info.json';
import './json/de/common.json';
import './json/de/content.json';
import './json/de/forms.json';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Axios from 'axios';
import { appConfig, connectionConfig } from './environment';
import { ConnectionProvider } from './providers/ConnectionProvider';
import { DeviceProvider } from './providers/DeviceProvider';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { getQueryParams } from './utils/url';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let redirect = "";

console.log(window.location.href);
if (window.location.pathname == '/') {
  const page = getQueryParams(window.location.search, "page");
  if (page && page != "") {
    let search = window.location.search.replace(`&page=${page}`, "").replace(`?page=${page}`, "?").replace("?&", "?");
    if (search == "?") 
      search = "";
    redirect = `${window.location.origin}/${page}${search}`;
  }
}

let connected = true;
const userAgent = navigator.userAgent;
const device = getSelectorsByUserAgent(userAgent);
connected = navigator.onLine;

// Don't allow FF users the FF flickering experience and send them to the SSR-side directly
// if ((device) && ((device.browserName + "").toLowerCase() === 'firefox')) {
//   window.location.href = `${appConfig.switchUrl}${window.location.pathname}${window.location.search}`;
// } else {
//  if (redirect != '') {
//   Axios.get(window.location.origin)
//   .then((res: any) => {
//     window.location.href = redirect;
//   })
//   .catch((err: any) => {
//   })  
//  } else

//  if (redirect != '') {
//   window.location.href = redirect;
//  }


   root.render(
    <React.StrictMode>
      <DeviceProvider initialDevice={device}>
        <ConnectionProvider initialConnected={connected}>
          <App redirect={redirect}/>
        </ConnectionProvider>
      </DeviceProvider>,
    </React.StrictMode>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//}


//  })

