import options from "../i18nextOptions";

export const getLanguage = (lang?: string | undefined | null) => {
    const langs = options.supportedLngs;
    if (lang && ((langs?.length == 0) || langs?.includes(lang)))
        return lang;
    const langNav = navigator?.language?.split("-")[0];
    if ((langs?.length == 0) || langs?.includes(langNav))
        return langNav;
    return options.fallbackLng;
} 