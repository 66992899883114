import React, { createContext, useState, useContext, ReactNode } from 'react';

// Definieer het type voor de contextwaarde
interface ConnectionContextType {
  connected: boolean;
  setConnected: React.Dispatch<React.SetStateAction<boolean>>;
}

// Maak de context aan met een initiële waarde van undefined
const ConnectionContext = createContext<ConnectionContextType | undefined>(undefined);

// Definieer de props voor de provider
interface ConnectionProviderProps {
  children: ReactNode;
  initialConnected: boolean;
}

// Creëer de provider component
export const ConnectionProvider: React.FC<ConnectionProviderProps> = ({ children, initialConnected }) => {
  const [connected, setConnected] = useState<boolean>(initialConnected);

  return (
    <ConnectionContext.Provider value={{ connected, setConnected }}>
      {children}
    </ConnectionContext.Provider>
  );
};

// Custom hook om de context te gebruiken
export const useConnection = (): ConnectionContextType => {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }
  return context;
};