import { Content } from "../buildingblocks/Content";
import { FC, Suspense, useContext } from "react";
import { AppContext } from "../providers/AppProvider";
import { useTranslation } from "react-i18next";
import { InfoContainer, getName, getItems } from "../buildingblocks/InfoContainer";
import { CompanyBullet } from "../icons/CompanyBulletIcon";
import { ColorScheduleContext } from "../providers/ColorScheduleProvider";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;

  return (
    <Suspense>
      <Content locale={locale} indent={indent}>
        <SubContent locale={locale} indent={indent}/>
      </Content>      
    </Suspense>
  );
}
export const SubContent: FC<any> = (props) => {
  const { locale, indent } = props;
  const className = "ext-paragraph" + (indent ? "-indent" : "");

  return (
    <>
      <div style={{}}>
        <ContentRole locale={locale} indent={indent} prefix="website.online" />
        <ContentRole locale={locale} indent={indent} prefix="website.offline" />
      </div>
      <div className={className}>
        <Content locale={locale} info="aboutsite/read" add={true} />
        </div>
        </>
  )
};

export const ContentRole: FC<any> = (props) => {
  const { locale, prefix, content, indent } = props;
  const { t } = useTranslation(["info"]);
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  const controlStyle = {
    backgroundColor: colorSchedule.headerColorFrom
  }

  const iconColor = colorSchedule.iconColor;
  const stopColor = colorSchedule.icon2Color;
  const containerStyle = {
    color: colorSchedule.containerTextColor,
    background: `linear-gradient(to bottom,  ${colorSchedule.containerColorFrom} 0%,${colorSchedule.containerColorTo} 100%)`,
    boxShadow: `10px 5px 5px ${colorSchedule.containerShadowColor}`
  }
  const textStyle = {
    color: colorSchedule.containerExplanationColor
  }

  const bullet = <CompanyBullet color={props.iconColor} stopColor={props.icon2Color} />

  return (
    <>
      <InfoContainer
        prefix={prefix}
        content={content}
        color={iconColor} stopColor={stopColor}
        containerStyle={containerStyle}
        textStyle={textStyle}
        name={getName(prefix, t)}
        items={getItems(prefix, t)}
        locale={locale}
        indent={indent}
        bullet={bullet}
      />
    </>
  )
};

export default Index;
