//import { useSearchParams } from "@remix-run/react";
import React from "react";
import { useState } from "react";
import { getNavigation } from "../utils/menuitems";

export const InfoContainer = (props: any) => {
  const { locale, name, items, indent } = props;
  const nightMode = undefined;
  const sideBars = undefined;

  const [hover, setHover] = useState(false);

  let i = -1;
  const link = props.content && props.content != "";

  const element = !link ?
    (<div style={{ color: "white", cursor: "default", textDecoration: "none" }}>
      {name}
    </div>) : (
      <a href={link ? getNavigation(props.content, locale, nightMode, sideBars) : ""} key={"innerLink_" + props.prefix}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{ color: "white", cursor: "pointer", textDecoration: hover ? "underline" : "none" }}>
        {name}
      </a>
    );

    return (
      <div className={"main-table" + ( indent ? "-indent" : "")} key={props.prefix}>
      <div className={"main-table" + ( indent ? "-indent" : "")} key={props.prefix + "_0"}>
        <div className='main-row' key={props.prefix + "_1"}>
          <div className='main-cell' key={props.prefix + "_2"}>
            <div style={props.containerStyle} className="role-container" key={props.prefix + "_3"}>
              {element}
            </div>
          </div>
          <div className='main-cell' key={props.prefix + "_4"}>
            <div className='item-table' style={props.textStyle} key={props.prefix + "_5"}>
              {(!items || !Array.isArray(items) ? [] : items).map((item: any) => {
                if (item == undefined)
                  return <></>;
                i++;
                return (
                  <div className='item-row' key={props.prefix + "6_" + i}>
                    <div className='bullet-cell' key={props.prefix + "7_" + i}>{ props.bullet }</div>
                    <div className='item-cell' key={props.prefix + "8_" + i}>
                      {item}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      </div>
    );
}

export function getName(prefix: string, t: any): string {
  return t(prefix + ".name");
}

export function getItems(prefix: string, t: any): [] {
  const obj = t(prefix + ".items", { returnObjects: true });
  if (obj && Array.isArray(obj))
    return (obj as []);
  else
    return [];
}
