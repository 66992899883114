const resources = {
    "menu": {
        "welcome": "Willkommen",
        "about": "Über",
        "contact": "Kontakt",
        "vision": "Vision",
        "automation": "Automatisierung",
        "analysis": "Analyse",
        "architecture": "Architektur"
    },
    "actions": {
        "daymode": "Tagmodus",
        "nightmode": "Nachtmodus",
        "webapp": "Zur App",
        "website": "Zur Webseite"
    }
}

export default resources;