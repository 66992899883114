import React, { createContext, useState, useContext, ReactNode } from 'react';

// Definieer het type voor de contextwaarde
interface DeviceContextType {
  device: any;
  setDevice: React.Dispatch<React.SetStateAction<any>>;
}

// Maak de context aan met een initiële waarde van undefined
const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

// Definieer de props voor de provider
interface DeviceProviderProps {
  children: ReactNode;
  initialDevice: any;
}

// Creëer de provider component
export const DeviceProvider: React.FC<DeviceProviderProps> = ({ children, initialDevice }) => {
  const [device, setDevice] = useState<any>(initialDevice);

  return (
    <DeviceContext.Provider value={{ device, setDevice }}>
      {children}
    </DeviceContext.Provider>
  );
};

// Custom hook om de context te gebruiken
export const useDevice = (): DeviceContextType => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};