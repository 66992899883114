const resources = {
    "content": {
        "content/notfound": {
            "title": "Nicht gefunden",
            "items": [
                {
                    "title": "",
                    "paragraph": "Ressource existiert nicht."
                }
            ]
        },
        "content/exception": {
            "title": "Ausnahmefehler",
            "items": [
                {
                    "title": "",
                    "paragraph": "Es ist eine Ausnahme aufgetreten. Bitte wenden Sie sich an den Administrator dieses Inhalts."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "Das ist ein Test."
                }
            ]
        },
        "welcome": {
            "title": "Willkommen",
            "items": []
        },
        "vision": {
            "title": "Vision",
            "items": []
        },
        "contact/sent": {
            "title": "Kontakt",
            "items": []
        },
        "contact/notsent": {
            "title": "Kontakt",
            "items": []
        },
        "about": {
            "title": "Über",
            "items": []
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": []
        },
        "analysis": {
            "title": "Analysis",
            "items": []
        },
        "architecture": {
            "title": "Architektur",
            "items": []
        }
    }
}

export default resources;