import { FC, useContext, useState } from 'react';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { Navigator } from "./Navigator";
import { BootstrapLayout } from './BootstrapLayout';

export const MenuBar: FC<any> = (props) => {
  const { menuItems, info } = props;
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  const [menu] = useState(menuItems);
    
  return (
    <div id="bar" style={{
      color: colorSchedule.menuTextColor,
      background: `linear-gradient(to bottom,  ${colorSchedule.menuBarColorFrom} 0%,${colorSchedule.menuBarColorTo} 100%)`,
      borderTopColor: colorSchedule.menuBarTopColor, borderBottomColor: colorSchedule.menuBarBottomColor,
    }} className='menu-bar'>
      <BootstrapLayout>
        <Navigator menuItems={menu} info={info}/>
      </BootstrapLayout>
    </div>
  )
}
