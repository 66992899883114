import * as React from "react";

export const CompanyBullet = ({
    height = "15px",
    width = "15px",
    color = "red",
    stopColor = "black",
    strokeWidth = 4,
    ...props
}: React.SVGProps<SVGElement>) => (

    <svg width={width} height={height}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="0" y="0" width="56" height="56" fill="transparent" />
            <circle cx="28" cy="28" r="25" fill="white" stroke={color} strokeWidth={strokeWidth} />
            <circle cx="28" cy="28" r="15" fill={stopColor} />
        </g>
    </svg>
);


