import { Content } from "../buildingblocks/Content";
import { Suspense, useContext } from "react";
import { BrowserView, MobileView, TabletView, deviceDetect, deviceType, getUA } from 'react-device-detect';
import { isMobile, isMobileOnly, isTablet, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone } from 'react-device-detect';
import { mobileVendor, mobileModel, engineName, engineVersion } from 'react-device-detect';
import { fullBrowserVersion, browserVersion, browserName, isBrowser, isChrome, isChromium, isFirefox, isSafari, isMobileSafari, isOpera, isIE, isEdge, isYandex, isSamsungBrowser } from 'react-device-detect';
import { osName, osVersion, isIOS, isIOS13, isIPad13, isIPod13, isElectron } from 'react-device-detect';
import { AppContext } from "../providers/AppProvider";

import { useState, useEffect } from "react";
import { appConfig, connectionConfig, i18nClientStepsConfig } from "../environment";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  const className = "ext-paragraph" + (indent ? "-indent" : "");

  return (
    <Suspense>
      <Content locale={locale} indent={indent}>
        <div className={className}>
          <SubContent/>
          {/* <SendMail/> */}
        </div>
      </Content>
    </Suspense>
  );
}

function SubContent(props: any) {
  const indent = props.indent;
  const className = "ext-paragraph" + (indent ? "-indent" : "");

  return (
    <div className={className} style={{ wordWrap: "break-word" }}>
      <div>
        Sidebars: {appConfig.sideBars.toString()}
      </div>
      <div>
        Nightmode: {appConfig.nightMode.toString()} ({appConfig.visualizeMode.toString()})
      </div>
      <div>
        Switch URL: {appConfig.switchUrl}
      </div>
      <div>
        Authorization URL: {appConfig.authorizationUrl}
      </div>
      <div>
        Mail URL: {appConfig.mailUrl}
      </div>
      <div>
        Online check URL: {connectionConfig.urlCheck}
      </div>
      <div>
        Session Storage: {appConfig.session.toString()}
      </div>
      <div>
        SSR: {appConfig.ssr.toString()}
      </div>
      <br/>
      <div>
        Clientside i18n Settings:
      </div>
      <div>
        {JSON.stringify(i18nClientStepsConfig)}
      </div>
    </div>
  )
}

const SendMail = () => {
  const mail = {
    name: "Erix the Programmer",
    email: "eric@mijneureka.nl", 
    message: "Big message"
  }

  const requestOptions: any = {
    method: 'POST',
    headers: {},
    body: JSON.stringify(mail),
  };

  const [data] = useFetch("https://softex.nl/mail", requestOptions);

  const content = JSON.stringify(data);
  return (
    <div>
      {content}
    </div>
  );
};

const useFetch = (url: string, options: any) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};

export default Index;
