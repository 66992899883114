const resources = {
    "company": {
        "name": "Softex",
        "motto": "We automate automation"
    },
    "menu": {
        "welcome": "Welcome",
        "about": "About",
        "contact": "Contact",
        "vision": "Vision",
        "system": "System",
        "automation": "Automation",
        "analysis": "Analysis",
        "architecture": "Architecture"
    },
    "actions": {
        "daymode": "Switch to daymode",
        "nightmode": "Switch to nightmode",
        "webapp": "Goto to app",
        "website": "Goto to website"
    }
}

export default resources;