const resources = {
    "forms": {
        "contact": {
            "name": "Name",
            "email": "E-mail",
            "message": "Message",
            "errors": {
                "name-is-required": "Name is required",
                "email-is-required": "E-mail is required",
                "email-invalid-expression": "E-mail is invalid",
                "message-is-required": "Message is required"
            },
            "actions": {
                "clear": "Clear",
                "send": "Send"
            }
        }
    }
}

export default resources;