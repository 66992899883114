import { useContext, useState } from "react";
import { MenuBar } from "./MenuBar";
import { IMenuItem, MenuItem, getNavigation } from "../utils/menuitems";
import { useTranslation } from "react-i18next";
import { AppContext } from "../providers/AppProvider";

export default function Index(props: any) {
  const { t } = useTranslation();
  const app = useContext(AppContext).app;
  const locale = app.language;
  const nightMode = app.nightMode;
  const sideBars = app.sideBars;

  const getMenuItems = () => {
    const getMenuItem = (content: string, label: string | undefined = undefined): IMenuItem => {
      const key = "menu." + (label ? label : content);
      return new MenuItem(t(key), getNavigation((content), locale, nightMode, sideBars), (content));
    }

    const welcome = `welcome`;
    const vision = `vision`;
    const contact = `contact`;
    const about = `about`;
    const aboutUs = `aboutus`;
    const system = `system`;
    const settings = `settings`;
    const aboutwebsite = `aboutsite`;

    let menuItems: IMenuItem[] = [
      getMenuItem(welcome),
      getMenuItem(vision),
      getMenuItem(contact),
      getMenuItem(aboutUs, about),
    ];

    return menuItems;
  };

  const [menu] = useState(getMenuItems());

  return (
    <MenuBar locale={locale} menuItems={menu} />
  )
}