import { useContext } from 'react';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { BootstrapLayout } from './BootstrapLayout';
import { AppContext } from '../providers/AppProvider';
import { Footer } from './Main';

export const Workspace = (props: any) => {
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
    const app = useContext(AppContext).app;
    const sideBars = app.sideBars;
    const style = 
        sideBars ? { background: `linear-gradient(to bottom, ${colorSchedule.jumbotronColorFrom} 0%,${colorSchedule.jumbotronColorTo} 98%)` } 
        : { opacity: "100%", background: `linear-gradient(to bottom, ${colorSchedule.surfaceColorFrom} 25%,${colorSchedule.surfaceColorTo} 98%)`, color: colorSchedule.surfaceTextColor, minHeight: "40em" };

    return (
        <div id="workspace" style={style}>
            <BootstrapLayout>
                <div id="wrapper" style={{ backgroundColor: colorSchedule.surfaceColorFrom }} className="text">
                    <div>
                        {props.children}
                    </div>
                </div>
            </BootstrapLayout>
            <Footer />
        </div>
    )
}
