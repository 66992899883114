export const BulletList = (props: any) => {
    const values = (props.children[0] + "").split(";");
    const item = values[0];
    const key = values.length > 1 ? values[1] : item;
  
    return (
      <div key={key} className="bullet-list">
        <div key={key + "_0"} className="bullet-list-header">
          {item}
        </div>
        {props.children.splice(1)}
      </div>
    );
  };
  
  export const BulletItem = (props: any) => {
    const values = (props.children[0] + "").split(";");
    const item = values[0];
    const key = values.length > 1 ? values[1] : item;
  
    return (
      <div key={key + "_1"} className="bullet-item">
        <div key={key + "_2"} className="bullet-item-container">
          <div key={key + "_3"} className="bullet">{props.bullet}</div>
          <div key={key + "_4"} className="bullet-text">{item}</div>
        </div>
      </div>
    );
  }; 
  