import * as React from "react";

export const CompanyTitleIcon = ({
    height = "26px",
    width = "200px",
    color = "black",
    ...props
  }: React.SVGProps<SVGElement>) => (

  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={width} height={height} 
    className='svgicon'
    viewBox="0 0 1468 192"
    preserveAspectRatio="xMidYMid meet">
      <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
        fill={color} stroke="none">
        <path d="M663 1869 c-264 -25 -492 -156 -578 -332 -90 -185 -54 -406 88 -546
        103 -101 212 -157 382 -198 59 -13 166 -17 675 -23 651 -7 659 -7 712 -60 62
        -63 18 -151 -92 -184 -47 -14 -141 -16 -750 -16 l-696 0 -179 -231 c-99 -128
        -181 -236 -183 -240 -2 -5 390 -9 880 -9 717 0 901 3 978 15 302 45 535 222
        595 452 18 68 19 191 4 265 -38 177 -190 337 -399 421 -149 59 -165 60 -780
        64 -311 2 -575 5 -587 8 -54 12 -73 84 -32 121 20 18 49 19 703 22 l683 2 181
        234 c100 129 182 237 182 240 0 9 -1693 4 -1787 -5z"/>
        <path d="M3638 1870 c-308 -24 -551 -124 -736 -304 -163 -158 -237 -323 -248
        -556 -19 -370 176 -677 541 -856 226 -110 360 -128 925 -121 395 4 408 5 515
        31 162 38 262 81 388 164 178 117 306 280 373 477 26 75 28 91 28 260 1 192
        -6 230 -60 353 -83 189 -270 361 -494 454 -205 86 -298 99 -755 103 -203 2
        -418 0 -477 -5z m830 -486 c209 -43 363 -187 392 -367 22 -138 -22 -270 -120
        -360 -71 -66 -139 -102 -236 -127 -70 -18 -111 -20 -459 -20 -348 0 -389 2
        -459 20 -97 25 -165 61 -236 127 -66 61 -105 134 -120 226 -37 234 125 445
        385 500 105 23 745 23 853 1z"/>
        <path d="M5530 955 l0 -925 290 0 290 0 0 350 0 350 488 1 487 0 178 232 179
        232 -666 3 -666 2 0 100 0 100 529 0 529 0 173 223 c95 122 179 230 187 240
        14 16 -34 17 -991 17 l-1007 0 0 -925z"/>
        <path d="M7705 1648 c-99 -127 -181 -235 -183 -239 -2 -5 165 -9 372 -9 l376
        0 0 -685 0 -685 290 0 290 0 0 685 0 685 277 2 277 3 183 233 c101 128 183
        235 183 237 0 3 -424 5 -942 5 l-943 0 -180 -232z"/>
        <path d="M9810 955 l0 -925 818 0 818 0 182 235 c100 129 182 237 182 240 0 3
        -322 5 -715 5 l-716 0 3 108 3 107 487 3 488 2 180 233 180 232 -668 5 -667 5
        -3 98 -3 97 534 0 534 0 181 234 c100 129 182 237 182 240 0 3 -450 6 -1000 6
        l-1000 0 0 -925z"/>
        <path d="M11872 1871 c2 -4 203 -214 448 -466 245 -251 446 -461 448 -466 2
        -5 -165 -183 -371 -396 -205 -214 -400 -416 -431 -450 l-58 -63 363 0 364 0
        314 331 315 330 21 -23 c12 -13 154 -161 314 -330 l293 -308 366 0 366 0 -100
        103 c-54 56 -223 230 -373 387 -151 157 -303 315 -339 351 l-63 66 398 408
        c219 225 424 437 456 472 l59 63 -370 0 -369 0 -199 -207 c-109 -115 -257
        -270 -330 -345 l-131 -137 -329 344 -329 344 -368 1 c-203 0 -367 -4 -365 -9z"/>
      </g>
    </svg>
  );