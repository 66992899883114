import { Content } from "../buildingblocks/Content";
import ContactForm from "../buildingblocks/ContactForm";
import { ContactProvider, DEFAULT_CONTACT, Property } from "../providers/ContactProvider";
import { Suspense, useContext, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import { appConfig } from "../environment";
import React from "react";

export interface mailResult {
  saved?: boolean,
  error?: any,
  debug?: boolean
}

export async function action({
  request, params, context
}: any) {
  const data = await request.formData();

  const email = data.get(Property.EMAIL) + "";
  const name = data.get(Property.NAME) + "";
  const message = data.get(Property.MESSAGE) + "";

  const body = {
    email, name, message
  }

  let result: mailResult = { saved: undefined, error: undefined };
  await fetch(appConfig.mailUrl, {
    method: "post", body: JSON.stringify(body), headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(() => {
      result = { saved: true, error: undefined };
    })
    .catch((error: any) => {
      result = { saved: false, error };
    })
  return JSON.stringify(result);
}

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  const storageKey = "ContactInfo"; 
  const className = "ext-paragraph" + (indent ? "-indent" : "");
  const initialValue: mailResult | undefined = {saved: undefined, error: undefined, debug: true }; 
  const [actionResult, setActionResult] = useState(initialValue);
  const { connected } = props;

  const action = async(data: any) => {
    // const name = DEFAULT_CONTACT[Property.NAME];//data.get(Property.NAME) + "";
    // const email =  DEFAULT_CONTACT[Property.EMAIL]; // data.get(Property.EMAIL) + "";
    // const message = DEFAULT_CONTACT[Property.MESSAGE];// data.get(Property.MESSAGE) + "";

    const name = data.get(Property.NAME) + "";
    const email =  data.get(Property.EMAIL) + "";
    const message = data.get(Property.MESSAGE) + "";
    
    const body = {
      email, name, message
    }
  
    let result: mailResult = { saved: undefined, error: undefined };
    await fetch(appConfig.mailUrl, {
      method: "post", body: JSON.stringify(body), headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        result = { saved: true, error: undefined };
      })
      .catch((error: any) => {
        result = { saved: false, error: error?.message };
      })
    setActionResult(result);
  }

  React.useEffect(() => {
    if (storageKey && actionResult.saved) {
      const storage = appConfig.session ? window.sessionStorage : window.localStorage;
      storage.setItem(storageKey, JSON.stringify(DEFAULT_CONTACT));
      //console.log("Locally stored contact data has been removed")
    }
  }, [actionResult.saved]);
    
  if (actionResult.saved !== undefined) {
    if (actionResult.saved) {
      return (
        <Suspense>
          <Content locale={locale} info="contact/sent" indent={indent}>
            <div className={className}>
            </div>
          </Content>
        </Suspense>
      )
    } else {
      return (
        <Suspense>
          <Content locale={locale} info="contact/notsent" indent={indent}>
            <div className={className}>
            { JSON.stringify(actionResult) }
            </div>
          </Content>
        </Suspense>
      )
    }
  } else {
    return (
      <Suspense>
        <Content locale={locale} indent={indent}>
          {/* <br/> */}
          <div className={className}>
            <ContactProvider>
              <ContactForm storageKey={storageKey} session={appConfig.session} connected={connected} onAction={action} />
            </ContactProvider>
          </div>
        </Content>
      </Suspense>
    );
  }
}

export default Index;