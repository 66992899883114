const resources = {
    "menu": {
        "welcome": "Welkom",
        "about": "Over",
        "contact": "Contact",
        "vision": "Visie",
        "automation": "Automatisering",
        "analysis": "Analyse",
        "architecture": "Architectuur"
    },
    "actions": {
        "daymode": "Naar dagmodus",
        "nightmode": "Naar nachtmodus",
        "webapp": "Naar de app",
        "website": "Naar de website"
    }
}

export default resources;