import * as React from "react";

export const CompanyIcon = ({
    height = "165px",
    width = "125px",
    color = "black",
    stopColor = "gray",
    strokeWidth = 4,
    ...props
  }: React.SVGProps<SVGElement>) => (

    <svg width={width} height={height} fill="none" 
        className='svgicon'
        viewBox="0 0 250 335"      
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <rect x="80" y="130" width="170" height="80" fill={color} />
          <line x1="40" y1="15" x2="40" y2="285" stroke={color} strokeWidth={strokeWidth} />
          <line x1="40" y1="90" x2="165" y2="90" stroke={color} strokeWidth={strokeWidth} />
          <line x1="40" y1="250" x2="165" y2="250" stroke={color} strokeWidth={strokeWidth} />
          <line x1="165" y1="88" x2="165" y2="252" stroke={color} strokeWidth={strokeWidth} />
          <circle cx="40" cy="15" r="15" fill={color} />
          <polygon points="40,50 0,90 40,130 80,90" fill={color} />
          <rect x="80" y="130" width="170" height="80" fill={color} />
          <circle cx="40" cy="305" r="25" fill="white" stroke={color} strokeWidth={strokeWidth} />
          <circle cx="40" cy="305" r="15" fill={stopColor} />
      </g>
    </svg>
    );
