import { Content } from "../buildingblocks/Content";
import { Suspense, useContext } from "react";
import { AppContext } from "../providers/AppProvider";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  const info = "aboutus";

  return (
    <Suspense>
      <Content locale={locale} info={info} indent={indent} />
    </Suspense>
  );
}

export default Index;
