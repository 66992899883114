import { Suspense, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ColorScheduleContext } from "../providers/ColorScheduleProvider";
import { GotoContent } from "./Hyperlinks";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { BulletItem, BulletList } from "./BulletList";
import { CompanyBullet } from "../icons/CompanyBulletIcon";
import { AppContext } from "../providers/AppProvider";

export function Content(props: any) {
  const { t, i18n } = useTranslation(["content", "common"]);
  const { locale, before, children, info, indent, className, add } = props;
  const location = useLocation();
  const app = useContext(AppContext).app;

  let content = (info != null && info != "") ? info : (location.pathname.substring(1) !== "" ? location.pathname.substring(1) : "welcome") + "";
  const exists = i18n.exists(`content:content.${content}`);
  if (!exists)
    content = "content/notfound";

  const nightMode = app.nightMode;
  const sideBars = app.sideBars;

  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  let contentKey = `content.${content?.toLowerCase()}.items`;
  let mainTitleKey = `content.${content?.toLowerCase()}.title`;

  const components = {
    italic: <i />, bold: <strong />
  };
  let index = -1;

  const obj = t(contentKey, { returnObjects: true });

  if (obj == null || !Array.isArray(obj) || obj.length == 0)
    return (
      <>
      <div>{t("messages.load-error")}</div>
      </>);

  const contentBefore = (before) ? <><p className="paragraph"></p>{children}</> : <></>
  const contentAfter = (!before) ? children : <></>

  const bullet = <CompanyBullet color={props.iconColor} stopColor={props.icon2Color} />

  return (
    <Suspense fallback={<Spinner style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />}>
      {/* <div id="content" style={{ paddingBottom: "25px", marginBottom: "35px", borderBottomLeftRadius: sideBars ? "10px" : undefined, borderBottomRightRadius: sideBars ? "10px" : undefined, opacity: "100%", background: `linear-gradient(to bottom, ${colorSchedule.surfaceColorFrom} 25%,${colorSchedule.surfaceColorTo} 98%)`, color: colorSchedule.surfaceTextColor, minHeight: "40em" }}> */}
      {/* <div id="content" style={{ paddingBottom: "25px", borderBottomLeftRadius: sideBars ? "10px" : undefined, borderBottomRightRadius: sideBars ? "10px" : undefined, opacity: "100%", background: `linear-gradient(to bottom, ${colorSchedule.surfaceColorFrom} 25%,${colorSchedule.surfaceColorTo} 98%)`, color: colorSchedule.surfaceTextColor, minHeight: ( add ? "auto" : "40em" ) }}> */}
      <div id="content" style={{ marginBottom: "35px", borderBottomLeftRadius: sideBars ? "10px" : undefined, borderBottomRightRadius: sideBars ? "10px" : undefined, opacity: "100%", background: (add ? "transparent" : `linear-gradient(to bottom, ${colorSchedule.surfaceColorFrom} 25%,${colorSchedule.surfaceColorTo} 98%)`), color: colorSchedule.surfaceTextColor, minHeight: ( add ? "auto" : "40em" )}}>
        <div style={{ padding: "1em  ", paddingLeft: (indent ? "1em" : "0em") }}>
          {
            (t(mainTitleKey)) == "" ? (<></>) : (<h4 key={"header_main_title"} style={{ color: colorSchedule.surfaceTitleColor }} className={"main-title" + (indent ? "-indent" : "")}>
              <Trans t={t} key={"main-title"} i18nKey={mainTitleKey} components={
                { goto: <GotoContent locale={locale} nightMode={nightMode} sideBars={sideBars} translation={Trans} linkColor={colorSchedule.surfaceTitleLinkColor} />, ...components }}>
              </Trans>
            </h4>)
          }
          {contentBefore}
          <div>
            {
              obj.map((item: any) => {
                index++;
                const section = contentKey + "." + index;
                const titleKey = section + ".title";
                const paragraphKey = section + ".paragraph";
                let title = t(titleKey) == "" ? (<></>) : (<h6 key={section + "_title"} style={{ color: colorSchedule.surfaceTitleColor }} className={"title" + (indent ? "-indent" : "")}>
                  <Trans t={t} key={section + "_trans_title"} i18nKey={titleKey} components={
                    { goto: <GotoContent locale={locale} nightMode={nightMode} sideBars={sideBars} translation={Trans} linkColor={colorSchedule.surfaceTitleLinkColor} />, ...components }}>
                  </Trans>
                </h6>);
                const paragraphList = t(paragraphKey);
                if (Array.isArray(paragraphList)) {
                  let listIndex = 0;
                  const list: any[] = [];
                  paragraphList.map((item: any) => {
                    const paragraphListKey = paragraphKey + "." + listIndex
                    const paragraphItem = t(paragraphListKey) == "" ? (<></>) : (
                      <Trans t={t} key={section + "_trans_paragraph" + listIndex} i18nKey={paragraphListKey} tOptions={{ interpolation: { escapeValue: true } }} components={
                        {
                          hyphen: <SoftHyphen />,
                          ul: <BulletList />, 
                          li: <BulletItem bullet={bullet} />,
                          goto: <GotoContent locale={locale} nightMode={nightMode} sideBars={sideBars} translation={Trans} linkColor={colorSchedule.linkColor} />, ...components
                        }}>
                      </Trans>
                    );
                    list.push(paragraphItem);
                    listIndex++;
                  });
                  return (
                    <div key={section}>
                      {title}
                      <div key={section + "_paragraph"} style={{ color: colorSchedule.surfaceTextColor }} className={(className ? className + " " : "") + "paragraph" + (indent ? "-indent" : "")}>                   
                        {list}
                      </div>
                    </div>);
                } else {
                  const paragraph = t(paragraphKey) == "" ? (<></>) : (<div key={section + "_paragraph"} style={{ color: colorSchedule.surfaceTextColor }} className={(className ? className + " " : "") + "paragraph" + (indent ? "-indent" : "")}>
                    <Trans t={t} key={section + "_trans_paragraph"} i18nKey={paragraphKey} tOptions={{ interpolation: { escapeValue: true } }} components={
                      {
                        hyphen: <SoftHyphen />,
                        ul: <BulletList />, 
                        li: <BulletItem bullet={bullet} />,
                        goto: <GotoContent locale={locale} nightMode={nightMode} sideBars={sideBars} translation={Trans} linkColor={colorSchedule.linkColor} />, ...components
                      }}>
                    </Trans>
                  </div>);
                  return (
                    <div key={section}>
                      {title}
                      {paragraph}
                    </div>
                  );
                }
              })}

          </div>
          {contentAfter}
        </div>
      </div>
    </Suspense>
  )
}

const SoftHyphen = () => {
  return <>&shy;</>
}
