// export interface IMenuItem {
//     name: string;
//     translationKey: string;
//     icon?: JSX.Element | undefined;
//     url: string;
//     enabled?: boolean | true;
// }

// export class MenuItem implements IMenuItem {
//      constructor (public readonly name: string, public readonly url: string, public readonly translationKey: string, public readonly icon?: JSX.Element, public readonly enabled = true) {}
// };

// export const getNavigation = (content: string, language: string, nightMode: boolean) => {
//     return `/${content}?lng=${language}&nightmode=${nightMode}`;
// };

export interface IMenuItem {
    name: string;
    enabled?: boolean | true;
    translationKey: string;
    icon?: JSX.Element | undefined;
    url: string;
    add?: boolean;
}

export class MenuItem implements IMenuItem {
     constructor (public readonly name: string, public readonly url: string, public readonly translationKey: string, public readonly icon?: JSX.Element, public readonly enabled = true, public readonly add = true) {}
};

export const getNavigation = (content: string, language: string | undefined, nightMode: boolean | undefined, sideBars: boolean | undefined) => {
    if (!language == undefined || nightMode == undefined || sideBars == undefined)
        return `/${content}`;
    return `/${content}?lng=${language}&nightmode=${nightMode}&sidebars=${sideBars}`;
};