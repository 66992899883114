const resources = {
  "forms": {
    "contact": {
      "name": "Name",
      "email": "E-mail",
      "message": "Botschaft",
      "errors": {
        "name-is-required": "E-mail ist verpflichtet",
        "email-is-required": "E-mail ist verpflichtet",
        "email-invalid-expression": "E-mail ist falsch",
        "message-is-required": "Botschaft ist verpflichtet"
      },
      "actions": {
        "clear": "Leeren",
        "send": "Schicken"
      }
    }
  }
}

export default resources;