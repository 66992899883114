const resources = {
    "content": {
        "content/notfound": {
            "title": "Not found",
            "items": [
                {
                    "title": "",
                    "paragraph": ""
                }
            ]
        },
        "content/exception": {
            "title": "Exception",
            "items": [
                {
                    "title": "",
                    "paragraph": "An exception has occurred. Please contact the administrator of this content."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "This is a test."
                }
            ]
        },
        "welcome": {
            "title": "Welcome",
            "items": []
        },
        "vision": {
            "title": "Vision",
            "items": []
        },
        "contact": {
            "title": "Contact",
            "items": []
        },
        "contact/sent": {
            "title": "Contact",
            "items": []
        },
        "contact/notsent": {
            "title": "Contact",
            "items": []
        },
        "about": {
            "title": "About",
            "items": []
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": []
        },
        "analysis": {
            "title": "Analysis",
            "items": []
        },
        "architecture": {
            "title": "Architecture",
            "items": []
        },
        "system": {
          "title": "System",
          "items": []
        }
    }
}

export default resources;