import { BrowserRouter, Route, Routes as BrowserRoutes, Navigate } from 'react-router-dom';
import MenuBarMain from '../buildingblocks/MenuBarMain';
import MenuBarAutomation from '../buildingblocks/MenuBarAutomation';
import { Suspense } from 'react';
import { appConfig } from '../environment';
import { getQueryParams } from '../utils/url';
import MenuBarAbout from '../buildingblocks/MenuBarAbout';


export default function Routes(props: any) {
  
  const redirectTo = () => {
    let search = window.location.search;
    const page = getQueryParams(search, "page")
    if (page != undefined && page > '') {
      search = search.replace(`?page=${page}`, '?').replace(`&page=${page}`, '').replace("?&", "?"); 
      return `/${page}${search}`;
    }
    return `/${appConfig.defaultContent}`;
  }

  return (
    <Suspense>
      <BrowserRouter>
        <BrowserRoutes> 
          <Route path="/" element={<Navigate to={redirectTo()} replace />} />        
          <Route path="/about" element={<MenuBarAbout info="aboutus"/>} />
          <Route path="/aboutus" element={<MenuBarAbout/>} />
          <Route path="/aboutsite" element={<MenuBarAbout/>} />
          <Route path="/aboutowner" element={<MenuBarAbout/>} />
          <Route path="/automation" element={<MenuBarAutomation/>} />
          <Route path="*" element={<MenuBarMain />} />
        </BrowserRoutes>
      </BrowserRouter>
    </Suspense>
  )
}
