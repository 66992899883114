import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { IMenuItem, getNavigation } from '../utils/menuitems';
import { Nav, Navbar } from 'react-bootstrap';
import i18nOptions from '../i18nextOptions';
import { SunIcon } from '../icons/SunIcon';
import { MoonIcon } from '../icons/MoonIcon';
import { SwitchIcon } from '../icons/SwitchIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { TooltipContainer, TooltipLink } from '../buildingblocks/Hyperlinks';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../providers/AppProvider';
import { appConfig, i18nClientStepsConfig } from '../environment';
import { clearCache } from '../i18n';
import { useConnection } from '../providers/ConnectionProvider';
import EmptyCacheIcon from '../icons/EmptyCacheIcon';

export const Navigator: FC<any> = (props) => {
  const { t } = useTranslation();
  const { connected } = useConnection();
  const app = useContext(AppContext).app;
  const locale = app.language;
  const nightMode = app.nightMode;
  const sideBars = app.sideBars;

  const { menuItems, info } = props;
  const navigate = useNavigate();
  const location = useLocation();

  let currentContent = info ? info : location.pathname.substring(1);
  if (currentContent == "") {
    currentContent = "welcome";
  }

  const currentLanguage = locale;
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  const [menu] = useState(menuItems);

  const switchUrl = appConfig.switchUrl;

  let [isToggled, setToggled] = useState(false);
  let [isExpanded, setExpanded] = useState(false);
  let [isReady, setReady] = useState(true);

  const canEmptyCache = connected && !i18nClientStepsConfig.embedded ? true : false;
  const canSwitch = connected ? true : false;

  const getMenuLink = (menuItem: IMenuItem) => {
    if (!menuItem.enabled)
      return "";
    let href = menuItem.url;
    if (menuItem.add) {
      if (menuItem.url.includes("?"))
        href += "&";
      else
        href += "?";
      href += `lng=${locale}&nightmode=${nightMode}&sidebars=${sideBars}`;
    }
    return href;
  }

  const getMenuItemColorStyle = (isExpanded: boolean, isEnabled = true) => {
    return {
      color: (isEnabled ? (isExpanded ? colorSchedule.pullDownMenuTextColor : colorSchedule.menuTextColor) : (isExpanded ? colorSchedule.pullDownMenuDisabledTextColor : colorSchedule.menuDisabledTextColor)),
      borderColor: colorSchedule.activeMenuColor,
      cursor: (isEnabled ? "pointer" : "default"),
    }
  }

  const getButtonColorStyle = (isExpanded: boolean, isEnabled = true) => {
    return {
      color: (isEnabled ? colorSchedule.menuTextColor : colorSchedule.menuDisabledTextColor),
      backgroundColor: isExpanded ? colorSchedule.menuBarColorTo : "transparent",
      borderColor: "transparent",
      cursor: (isEnabled ? "pointer" : "default"),
    }
  }

  const getClassName = (content: string, isExpanded: boolean, isEnabled = true): string => {
    if (isExpanded && (content === currentContent))
      return "expanded-active-menu-link"
    else if (isExpanded)
      return "expanded-menu-link"
    else if (content === currentContent) {
      return "active-menu-link"
    }
    else
      return "menu-link"
  }

  const buttonColorStyle = (isEnabled = true) => {
    if (isEnabled)
      return (
        {
          background: `transparent`,
          borderColor: `transparent`,
          color: colorSchedule.activeMenuColor
        }
      )
    else
      return (
        {
          background: `transparent`,
          borderColor: `transparent`,
          color: colorSchedule.menuDisabledTextColor
        }
      )
  }

  const buttonClassName = (isEnabled = true) => {
    if (isEnabled)
      return "";
    return "";
  }

  const toggleColorStyle = { border: "0px", color: colorSchedule.menuToggleLineColor, backgroundColor: colorSchedule.menuToggleColor };

  // const setColors = (): void => {
  //   const mode = !nightMode;
  //   navigate(getNavigation(currentContent, currentLanguage, mode));
  // }

  const ref = React.useRef(null);

  const onToggle = (toggled: boolean) => {
    if (!isReady)
      return;
    setReady(false);
    setToggled(toggled);
    if (toggled) {
      setExpanded(true);
      setReady(true);
    } else {
      const timer = setTimeout(() => {
        setExpanded(false);
        setReady(true);
      }, 350);
      return () => clearTimeout(timer);
    }
  }

  const onClick = (event: any) => {
    // console.log(event.srcElement.className);
    const name = (event.srcElement.className + ""); // shabby
    const selfInflicted = name.includes("navbar-toggler") // shabby // Need a name or an id
    if (isExpanded && !selfInflicted) {
      onToggle(false);
    }
  }

  const onEmptyCache = async (e: React.FormEvent<HTMLButtonElement>) => {
    clearCache();
  };

  useEffect(() => {
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isExpanded])

  useEffect(() => {
    const onResize = () => {
      if (isToggled)
        onToggle(false);
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [isToggled])

  let i = 0;
  let delimiter = (<span key={"delim_" + i} />);

  const icon = (appConfig.visualizeMode ? !nightMode : nightMode) ? (<SunIcon color={colorSchedule.nightModeColor} />) : (<MoonIcon color={colorSchedule.nightModeColor} />);
  const emptyCache =
    (appConfig.canEmptyCache ?
      <TooltipContainer enabled={canEmptyCache} tooltip={t("actions.emptycache")}>
        <button type="button" name="emptyCacheButton" disabled={!canEmptyCache} style={getButtonColorStyle(isExpanded, canEmptyCache)} onClick={onEmptyCache}>
          <EmptyCacheIcon color={canEmptyCache ? colorSchedule.menuTextColor : colorSchedule.menuDisabledTextColor} />
        </button>
      </TooltipContainer>
      : <></>)

  return (
    <Suspense>
      <div style={{ display: "table-row" }}>
        <div style={{ display: "table-cell", width: "85%", textAlign: "left" }}>
          <Navbar expanded={isToggled} style={{}} ref={ref} onToggle={onToggle} expand="lg" className="navigation-bar">
            <Navbar.Toggle as="div" aria-controls="basic-navbar-nav" style={toggleColorStyle} className="menu-toggle" />
            <Navbar.Collapse style={{
              color: (isExpanded ? colorSchedule.pullDownMenuTextColor : colorSchedule.menuTextColor),
              boxShadow: isExpanded ? `5px 5px 5px ${colorSchedule.pullDownMenuBoxShadowColor}` : undefined,
              borderColor: (isExpanded ? colorSchedule.pullDownMenuBorderColor : colorSchedule.menuBarBottomColor),
              background: (isExpanded ? `linear-gradient(to bottom, ${colorSchedule.pullDownMenuColorFrom} 20%,${colorSchedule.pullDownMenuColorTo} 100%)` : "transparent")
            }} className={isExpanded ? "nav-menu-expanded" : "nav-menu"} id="basic-navbar-nav">
              <Nav key="myNav">
                {menu.map((menuItem: IMenuItem) => {
                  return (
                    <Nav.Item key={menuItem.translationKey}>
                      <Nav.Link key={menuItem.translationKey + "_link"} active={false} style={getMenuItemColorStyle(isExpanded, menuItem.enabled)}
                        className={getClassName(menuItem.translationKey, isExpanded)}
                        href={menuItem.enabled ? menuItem.url : ""}>
                        {menuItem.name}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div style={{ display: "table-cell", width: "15% ", textAlign: "center" }}>
        </div>
        <div style={{ display: "table-cell", width: "25%", textAlign: "right" }}>
          {/* <div className="font-bar" style={isExpanded ? { display: "none" } : { display: "block" }}><div className="active-font-choice" style={{ fontSize: "1rem" }}>A</div><div className="font-choice" style={{ fontSize: "1.25rem" }}>A</div><div className="font-choice" style={{ fontSize: "1.5rem" }}>A</div></div> */}
          <div className='language-bar'>
            <div className='language-bar' style={{ textWrap: "nowrap" }}>
              {emptyCache}
              <TooltipLink tooltip={nightMode ? t("actions.daymode") : t("actions.nightmode")} className="nightmode" url={getNavigation(currentContent, currentLanguage, !nightMode, sideBars)} style={{}}>
                {icon}
              </TooltipLink>
              <TooltipLink enabled={canSwitch} tooltip={t("actions.website")} disabled={!canSwitch} className="switch-app" style={getButtonColorStyle(isExpanded, canSwitch)} url={switchUrl + getNavigation(currentContent, currentLanguage, nightMode, sideBars)}>
                {(<SwitchIcon color={canSwitch ? colorSchedule.menuTextColor : colorSchedule.menuDisabledTextColor} />)}
              </TooltipLink>
              {i18nOptions.supportedLngs.map((lang: string) => {
                let display = (<></>);
                if (lang === currentLanguage)
                  display = (
                    <span style={{ color: colorSchedule.menuTextColor, backgroundColor: "transparent", borderColor: colorSchedule.activeMenuColor }} className='active-language' key={"span_" + lang}>
                      {lang.toUpperCase()}
                    </span>);
                else
                  display = (
                    <span className='language' key={"span_" + lang}>
                      <a style={{ color: colorSchedule.menuTextColor }} href={getNavigation(currentContent, lang, nightMode, sideBars)} className="language">
                        {lang.toUpperCase()}
                      </a>
                    </span>);

                if (i !== 0) {
                  delimiter = (<span key={"delim_" + i}>|</span>)
                }
                i++;
                return (
                  <span key={"delim_span_" + lang}>{delimiter}{display}</span>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
