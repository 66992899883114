import React, { useEffect, useReducer } from 'react';
import { MainHeader } from './buildingblocks/Main';
import { colorScheduleReducer, NightColorSchedule, DayLightColorSchedule, ColorScheduleContext } from './providers/ColorScheduleProvider';
import Routes from './routes/ContentRoutes';
import { getQueryParams } from './utils/url';
import { Workspace } from './buildingblocks/Workspace';
import MenuRoutes from './routes/MenuRoutes';
import { AppContext, DEFAULT_APP, appReducer } from './providers/AppProvider';
import { getSelectorsByUserAgent, useDeviceSelectors } from 'react-device-detect';
import { getLanguage } from './utils/languages';
import { clearCache } from './i18n';
import { useNavigate } from 'react-router-dom';
import { appConfig, connectionConfig, i18nClientStepsConfig } from './environment';
import { useConnection } from './providers/ConnectionProvider';
import { DeviceProvider } from './providers/DeviceProvider';
import i18next from 'i18next';

export default function App(props: any) {
  const href = window.location.href;

  const { connected, setConnected } = useConnection();
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
  const { isMobileOnly } = selectors

  const nightModeParam = getQueryParams(href, "nightmode");
  const nightMode = nightModeParam ? (nightModeParam.toLowerCase() == "true") : appConfig.nightMode; 

  const sideBarsParam = getQueryParams(href, "sidebars")
  const sideBars = sideBarsParam ? (sideBarsParam.toLowerCase() == "true") : appConfig.sideBars && !isMobileOnly; 

  const languageParam = getQueryParams(href, "lng")
  const language = getLanguage(languageParam) || i18next.language;

  const [colorSchedule, dispatch] = useReducer(colorScheduleReducer, nightMode ? NightColorSchedule : DayLightColorSchedule);
  const appValueAction = useReducer(appReducer, DEFAULT_APP);
  const bgColor = sideBars ? colorSchedule.jumbotronColorTo : colorSchedule.surfaceColorTo;
  let key = "href";

  const app = appValueAction[0];
  app.nightMode = nightMode;
  app.sideBars = sideBars;
  app.language = language;

  document.body.style.backgroundColor = bgColor;

  const userAgent = navigator.userAgent;
  const device = getSelectorsByUserAgent(userAgent);

  console.log(`App started connected: ${props.connected}`);

  useEffect(() => {
    const handleOnline = () => {
      // Perform actions when the device is online
      console.log("Going online");
      if (!i18nClientStepsConfig.fallback.useCache) {
        console.log("Clearing the cache");
        clearCache();
        key = "online";
        //window.location.href = window.location.href;
      }
      setConnected(true);
    };
    if (connectionConfig.enabled)
      window.addEventListener('online', handleOnline);
    return () => {
      if (connectionConfig.enabled)
        window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      // Perform actions when the device is online
      console.log("Going offline");
      setConnected(false);
      //      window.location.href = window.location.href;
    };
    if (connectionConfig.enabled)
      window.addEventListener('offline', handleOffline);
    return () => {
      if (connectionConfig.enabled)
        window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // if (device)
  //   if ((device.browserName + "").toLowerCase() === 'firefox') {
  //     window.location.href = "https://softex.nl";
  //   }

  return (
    <div id="app" style={{ minHeight: "100%" }} key={key}>
      <div id="win" style={{ minHeight: "100%" }}>
        <AppContext.Provider
          value={{ app, dispatch: appValueAction[1] }}>
          <DeviceProvider initialDevice={device}>
            <ColorScheduleContext.Provider
              value={{ colorSchedule, dispatch }}>
              <MainHeader />
              <MenuRoutes/>
              <Workspace>
                <Routes/>
              </Workspace>
            </ColorScheduleContext.Provider>
          </DeviceProvider>
        </AppContext.Provider>
      </div>
    </div>
  )
}
