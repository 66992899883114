import React, { Context, createContext, useReducer, useEffect, ReactNode } from "react";

const contactStorage = "ContactInfo";
const storage = false;

const contactUrl = "";
const axiosResult = "data";

// Properties involved

export enum Property {
    NAME = "name",
    EMAIL = 'email',
    MESSAGE = 'message'
}

export interface IContact {
    [Property.NAME]: string;
    [Property.EMAIL]: string;
    [Property.MESSAGE]: string;
}

export const DEFAULT_CONTACT: IContact = {
    [Property.NAME]: "",
    [Property.EMAIL]: "",
    [Property.MESSAGE]: ""
};

// Actions/Reducer

export enum ContactActionType {
    SET_PROPERTY = 'SET_PROPERTY', // General purpose action to set a property (Actions can be dispatched by passing an array of actions) 
    RUN = 'RUN', // Enables the execution of an outside function by passing the new object
    REFRESH = 'REFRESH', // Does nothing other than recreating the object
    CLEAR = 'CLEAR', // Clears all properties
    SEND = 'SEND', // 
    SENT = 'SENT' // 
}

export type ContactAction = {
    type: ContactActionType;
    value?: any;
    name?: string;
    function?: any;
}

export const contactReducer = (contact: IContact, action: ContactAction | ContactAction[]): IContact => {
    contact = { ...contact };
    if (Array.isArray(action)) {
        action.forEach(async (subAction) => {
            contactReducerAction(contact, subAction);
        });
    } else {
        contactReducerAction(contact, action);
    }
    return contact;
}

const contactReducerAction = async (contact: IContact, action: ContactAction) => {
    if (action)
        switch (action.type) {
            case ContactActionType.SET_PROPERTY: {
                if (action.name)
                    if (Object.values(Property).includes(action.name as Property))
                        (contact as any)[action.name] = action.value;
                    else
                        throw new Error("Property doesn't exist");
                break;
            }
            case ContactActionType.RUN: {
                // if (typeof (action.function) == "function") {
                //     action.function(contact);
                // }
                break;
            }
            case ContactActionType.SEND: {
                break;
            }
            case ContactActionType.SENT: {
                break; // Change
            }
            case ContactActionType.CLEAR: {
                contact[Property.NAME] = "";
                contact[Property.EMAIL] = "";
                contact[Property.MESSAGE] = "";
                break;
            }
            case ContactActionType.REFRESH: {
                break; // Change
            }
            default:
                throw new Error("Action doesn't exist");
        }
}
// Context and Provider
interface ProviderProps {
    children: ReactNode;
}

export interface IContactContext {
    contact: IContact
    dispatch: React.Dispatch<ContactAction | ContactAction[]>;
}

export const ContactContext: Context<IContactContext> = createContext(
    {} as IContactContext
);

export const initState: IContact =
    (storage && JSON.parse(localStorage.getItem(contactStorage) as string)) || DEFAULT_CONTACT;


export const ContactProvider: React.FC<ProviderProps> = ({ children }) => {
    const [contact, dispatch] = useReducer(contactReducer, initState);

    useEffect(() => {
        //
    }, [contact]);

    return (
        <ContactContext.Provider value={{ contact, dispatch }}>
            {children}
        </ContactContext.Provider>
    );
};















// HOC

// export const withContactContext = (Component: React.Component) => {
//     class ContactComponent extends React.Component {
//         render() {
//             return (
//                 <ContactContext.Consumer>
//                 {({ contact }) => <React.Component contact={ contact } />}
//                     < /ContactContext.Consumer>
//       );
//     }
//   }

// return ContactComponent;
// };

// export const withContactProvider = (Component: React.Component) => {
//     class ContactComponent extends React.Component {
//         render() {
//             return (
//                 <ContactProvider>
//                 <React.Component />
//                 < /ContactProvider>
//             );
//         }
//     }

//     return ContactComponent;
// };