const resources = {
    "forms": {
        "contact": {
          "name": "Naam",
          "email": "E-mail",
          "message": "Boodschap",
          "errors": {
            "name-is-required": "Naam is verplicht",
            "email-is-required": "E-mail is verplicht",
            "email-invalid-expression": "E-mail is onjuist",
            "message-is-required": "Boodschap is verplicht"
          },
          "actions": {
            "clear": "Leegmaken",
            "send": "Verzenden"
          }      
        }
      }    
}

export default resources;