import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import i18nextOptions from './i18nextOptions'
import Axios from 'axios';

import automationResourcesEN from './locales/en/info'; 
import commonResourcesEN from './locales/en/common'; 
import contentResourcesEN from './locales/en/content'; 
import formsResourcesEN from './locales/en/forms'; 
import automationResourcesNL from './locales/nl/info'; 
import commonResourcesNL from './locales/nl/common'; 
import contentResourcesNL from './locales/nl/content'; 
import formsResourcesNL from './locales/nl/forms'; 
import automationResourcesDE from './locales/de/info'; 
import commonResourcesDE from './locales/de/common'; 
import contentResourcesDE from './locales/de/content'; 
import formsResourcesDE from './locales/de/forms'; 

function getResources(automationResources: any, commonResources: any, contentResources: any, formsResources: any) {
  const automation = { ...automationResources};
  const common = { ...commonResources};
  const content = { ...contentResources};
  const forms = { ...formsResources};  
  return { automation, common, content, forms};
}

const resources: any = {
  en: getResources(automationResourcesEN, commonResourcesEN, contentResourcesEN, formsResourcesEN),
  de: getResources(automationResourcesDE, commonResourcesDE, contentResourcesDE, formsResourcesDE),
   nl: getResources(automationResourcesNL, commonResourcesNL, contentResourcesNL, formsResourcesNL)
 }

// import automationResourcesEN from './json/en/automation.json';
// import commonResourcesEN from './json/en/common.json';
// import contentResourcesEN from './json/en/content.json';
// import formsResourcesEN from './json/en/forms.json';
// import automationResourcesNL from './json/nl/automation.json';
// import commonResourcesNL from './json/nl/common.json';
// import contentResourcesNL from './json/nl/content.json';
// import formsResourcesNL from './json/nl/forms.json';
// import automationResourcesDE from './json/de/automation.json';
// import commonResourcesDE from './json/de/common.json';
// import contentResourcesDE from './json/de/content.json';
// import formsResourcesDE from './json/de/forms.json';

// const get = (automationResources: any, commonResources: any, contentResources: any, formsResources: any) => {
//   const automation = { ...automationResources };
//   const common = { ...commonResources };
//   const content = { ...contentResources };
//   const forms = { ...formsResources };
//   return { automation, common, content, forms };
// }

// export const getResources = () => {
//   const resources: any = {
//     en: get(automationResourcesEN, commonResourcesEN, contentResourcesEN, formsResourcesEN),
//     de: get(automationResourcesDE, commonResourcesDE, contentResourcesDE, formsResourcesDE),
//     nl: get(automationResourcesNL, commonResourcesNL, contentResourcesNL, formsResourcesNL)
//   }
//   return resources;
// }

//const resources = getResources();

export default resources;