import { BrowserRouter, Route, Routes as BrowserRoutes, Navigate } from 'react-router-dom';
import About from '../pages/About';
import Automation from '../pages/Automation';
import Contact from '../pages/Contact';
import Vision from '../pages/Vision';
import Welcome from '../pages/Welcome';
import NotFound from '../pages/NotFound';
import System from '../pages/System';
import Settings from '../pages/Settings';
import { Suspense } from 'react';
import { appConfig } from '../environment';
import { getQueryParams } from '../utils/url';
import AboutUs from '../pages/AboutUs';
import AboutSite from '../pages/AboutSite';
import AboutOwner from '../pages/AboutOwner';

export default function Routes(props: any) {

  const redirectTo = () => {
    let search = window.location.search;
    const page = getQueryParams(search, "page")
    if (page != undefined && page > '') {
      search = search.replace(`?page=${page}`, '?').replace(`&page=${page}`, '').replace("?&", "?"); 
      return `/${page}${search}`;
    }
    return `/${appConfig.defaultContent}`;
  }

  return (
    <Suspense>
      <BrowserRouter>
        <BrowserRoutes>
          <Route path="/" element={<Navigate to={redirectTo()} replace />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/about" element={<About />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/aboutsite" element={<AboutSite />} />
          <Route path="/aboutowner" element={<AboutOwner />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/system" element={<System />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </BrowserRoutes>
      </BrowserRouter>
    </Suspense>
  )
}
