import React, { Context, createContext, useReducer, useEffect, ReactNode } from "react";
import { getLanguage, getCountry } from "../utils/cultures";
import i18nextOptions from "../i18nextOptions";

const appStorage = "AppStorage";
const storage = false;

// Properties involved

const defaultContent = "welcome";

export interface IApp {
  content: string;
  language: string;
  country: string;
  sideBars: boolean;
  nightMode: boolean;
}

export const DEFAULT_APP: IApp = {
  content: defaultContent,
  language: i18nextOptions.fallbackLng,
  country: "", //getCountry(),
  sideBars: true, //process.env.REACT_APP_SIDE_BARS == "true",
  nightMode: process.env.REACT_APP_NIGHT_MODE == "true"
};

// Actions/Reducer

export enum AppActionType {
  CHANGE_CONTENT = 'CONTENT',
  CHANGE_LANGUAGE = 'LANGUAGE',
  CHANGE_COUNTRY = 'COUNTRY',
  CHANGE_SIDEBARS = 'SIDEBARS',
  CHANGE_NIGHTMODE = 'NIGHTMODE'
}

export type AppAction = {
  type: AppActionType;
  payLoad?: any;
}

export const appReducer = (app: IApp, action: AppAction | AppAction[]): IApp => {
  const newApp = {...app};
  if (Array.isArray(action)) {
    action.forEach((subAction) => {
      appReducerAction(newApp, subAction);
    });
  } else {
      appReducerAction(newApp, action);
  }
  return newApp;
}

const appReducerAction = (app: IApp, action: AppAction) => {
  if (action)
    switch (action.type) {
      case AppActionType.CHANGE_CONTENT: {
        app.content = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_LANGUAGE: {
        app.language = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_COUNTRY: {
        app.country = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_SIDEBARS: {
        app.sideBars = action.payLoad;
        break;
      }
      case AppActionType.CHANGE_NIGHTMODE: {
        app.nightMode = action.payLoad;
        break;
      }
    }
}

// Context and Provider

interface ProviderProps {
  children: ReactNode;
}

export interface IAppContext {
  app: IApp
  dispatch: React.Dispatch<AppAction | AppAction[]>;
}

export const AppContext: Context<IAppContext> = createContext(
  {} as IAppContext
);

export const initState: IApp =
  (storage && JSON.parse(localStorage.getItem(appStorage) as string)) || DEFAULT_APP;

export const AppProvider: React.FC<ProviderProps> = ({ children } ) => {
  const [app, dispatch] = useReducer(appReducer, initState);

  useEffect(() => {
    if (storage)
      localStorage.setItem(appStorage, JSON.stringify(app));
  }, [app]);

  return (
    <AppContext.Provider
      value={{ app, dispatch }}>
        {children}
    </AppContext.Provider>
  );
};